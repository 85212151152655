import React from "react"
import tw, { styled } from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"

import ProductCards from "components/product-cards"

const Styles = styled.div``

const LargeTitle = styled.h2`
  ${tw`py-2 w-full font-display font-bold text-white text-center`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 79px;
  }
`

const Paragraph = styled.div`
  ${tw`font-body text-white`}
  font-size: 13px;
  line-height: 25px;
  @media (min-width: 640px) {
    font-size: 17px;
    line-height: 27px;
  }
`

const CARDS_FOR_PAGE = {
  demand360: [
    "content-valuation-maxi-card",
    "affinity-solutions",
    "ip-topic-demand",
    "supply-metadata",
  ],
  "content-valuation": [
    "demand360-maxi-card",
    "affinity-solutions",
    "ip-topic-demand",
    "supply-metadata",
  ],
  "affinity-solutions": [
    "content-valuation-maxi-card",
    "demand360",
    "ip-topic-demand",
    "supply-metadata",
  ],
  "plexie": [
    "content-valuation-maxi-card",
    "demand360",
    "ip-topic-demand",
    "supply-metadata",
  ],
  "ip-topic-demand": [
    "content-valuation-maxi-card",
    "demand360",
    "affinity-solutions",
    "supply-metadata",
  ],
  "supply-metadata": [
    "content-valuation-maxi-card",
    "demand360",
    "affinity-solutions",
    "ip-topic-demand",
  ],
  "content-panorama": [
    "demand360-maxi-card",
    "affinity-solutions",
    "ip-topic-demand",
    "supply-metadata",
  ],
  API: [
    "demand360-maxi-card",
    "affinity-solutions",
    "ip-topic-demand",
    "supply-metadata",
  ],
}

const ExploreOtherProducts = ({ page, className }) => {
  const query = graphql`
    query ExploreOtherProducts {
      gcms {
        contentBlocks(where: { identifier: "explore-other-products-section" }) {
          title
          headerContent {
            html
          }
        }
      }
    }
  `

  const data = useStaticQuery(query)

  const sectionInfo = data.gcms.contentBlocks[0]

  if (!sectionInfo) {
    return null
  }

  return (
    <Styles className={className}>
      <LargeTitle style={{ textAlign: "center" }}>
        {sectionInfo.title}
      </LargeTitle>
      <Paragraph className="pt-6 pb-16 l:px-40 m:px-16 mx-auto max-w-s m:max-w-m l:max-w-l">
        <div
          className="text-yellow font-body text-sm m:text-base l:text-xl text-center"
          dangerouslySetInnerHTML={{
            __html: sectionInfo.headerContent?.html,
          }}
        />
      </Paragraph>
      <ProductCards cardKeys={CARDS_FOR_PAGE[page]} />
    </Styles>
  )
}

export default ExploreOtherProducts
